import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { BrowserRouter } from 'react-router-dom';
import {
  ThemeProvider as StyledCompThemeProvider,
  StyleSheetManager,
} from 'styled-components';
import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SnackbarProvider } from 'notistack';

import {
  EConditionType,
  useAuth,
  useAuthTokensAutoUpdate,
  useBeforeUnload,
  useBusinessLogic,
  useCustomerAccount,
  useInAppNotifications,
  usePendingOrders,
  usePositions,
  useTradeResults,
  useTradingAccounts,
  useAutoLogoutWithNoActivity,
  useAppVersion,
  useInternetStatus,
  useInstrumentsTradingUpdater,
  usePositionsConversionRate,
  usePositionsCurrentPrice,
  useChallenges,
  useSignalPurchase,
  useExpiredPasswordChecker,
} from '@trader/hooks';
import {
  indexDBService,
  initSentry,
  SmartLookService,
  useI18next,
} from '@trader/services';
import { EThemes, getTheme, GlobalStyles, IThemeOptions } from '@trader/themes';
import { NotificationContainer } from '@trader/notifications';
import { rootStore, RootStoreProvider, useMst } from '@trader/store';
import { ICustomRouteObject, RenderRoutes } from '@trader/navigation';
import { setMobileHeight } from '@trader/utils';
import { TradingNotificationsContextProvider } from '@trader/contexts';

interface IProps {
  darkTheme: IThemeOptions;
  lightTheme: IThemeOptions;
  routes: ICustomRouteObject[];
  hasSmartLook?: boolean;
}

const AppContainer = observer(
  ({ darkTheme, lightTheme, routes, hasSmartLook }: IProps) => {
    const store = useMst();

    const { currentLng } = useI18next();
    const isRtl = currentLng === 'ar';

    const { applyCondition } = useBusinessLogic(EConditionType.ScoreLevel);

    /* Socket subscriptions */
    useAuth();
    useCustomerAccount();
    usePendingOrders();
    usePositions();
    useChallenges();
    useSignalPurchase();
    usePositionsCurrentPrice();
    usePositionsConversionRate();
    useTradeResults();
    useTradingAccounts();

    useBeforeUnload();
    useAuthTokensAutoUpdate();
    useInAppNotifications();
    useInstrumentsTradingUpdater();
    useAutoLogoutWithNoActivity();
    useExpiredPasswordChecker();

    useEffect(() => {
      initSentry();

      if (hasSmartLook) {
        SmartLookService.init();
      }
    }, []);

    useEffect(() => {
      store.auth.isAuth && indexDBService.initialize();
    }, [store.auth.isAuth]);

    useEffect(() => {
      store.auth.isAuth && applyCondition();
    }, [
      store.user.profile.isRegistrationCompleted,
      store.user.profile.scoreLevel,
      store.auth.isAuth,
      store.user.activeTradingAcc(),
    ]);

    useEffect(() => {
      document.documentElement.setAttribute('dir', isRtl ? 'rtl' : 'ltr');
    }, [currentLng]);

    const themeMode = store.app.themeMode;
    const themeOptions = themeMode === EThemes.Dark ? darkTheme : lightTheme;
    const theme = getTheme(themeMode, themeOptions, isRtl);

    return (
      <StyleSheetManager stylisPlugins={isRtl ? [rtlPlugin] : []}>
        <MuiThemeProvider theme={theme}>
          <StyledCompThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
              <CssBaseline />
              <GlobalStyles />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <SnackbarProvider maxSnack={4}>
                  <RenderRoutes routes={routes} />
                  <NotificationContainer />
                </SnackbarProvider>
              </LocalizationProvider>
            </StyledEngineProvider>
          </StyledCompThemeProvider>
        </MuiThemeProvider>
      </StyleSheetManager>
    );
  }
);

export const AppInitializer = observer((props: IProps) => {
  setMobileHeight();
  // disableConsoleErrors();
  useAppVersion();
  useInternetStatus();

  return (
    <BrowserRouter>
      <TradingNotificationsContextProvider>
        <RootStoreProvider value={rootStore}>
          <AppContainer {...props} />
        </RootStoreProvider>
      </TradingNotificationsContextProvider>
    </BrowserRouter>
  );
});
