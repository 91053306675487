import React from 'react';
import { observer } from 'mobx-react-lite';

import { Icon, Typography, Stack } from '@trader/components';
import { TTradingAccountEntity, useMst } from '@trader/store';
import { EAccountTypeNames, isChallengeAccountType } from '@trader/constants';
import { useI18next } from '@trader/services';

import { useAccountInformationData } from './useAccountInformationData';
import { useAccountCalculatedValues } from './useAccountCalculatedValues';
import { AddFundsButton } from './addFundsButton';
import { ChangeAccountButton } from './changeAccountButton';
import * as Styled from './desktop.styled';

export const DesktopAccountInformation: React.FC = observer(() => {
  const { accounts } = useAccountInformationData();

  return (
    <Styled.Root>
      <Stack direction='column' spacing='46px' padding='6px 18px 18px 18px'>
        {accounts.map(account => (
          <Account key={account.platformLogin} account={account} />
        ))}
      </Stack>
    </Styled.Root>
  );
});

const Account: React.FC<{
  account: TTradingAccountEntity;
}> = observer(({ account }) => {
  const { translate, currentLng } = useI18next();
  const accountCalculatedValues = useAccountCalculatedValues(account);

  const store = useMst();
  const challenge = store.user.getAccountChallenge(account.tradingId);

  const isLiveAccount = account.accountType === EAccountTypeNames.Live;

  const handleOpenAccountStatementModal = () => {
    store.ui.modal.open('accountStatement', {
      platformLogin: account.platformLogin,
      tradingAccountCurrency: account.currency,
      product: account.product,
      accountType: account.accountType,
    });
  };

  return (
    <Styled.Account>
      <Stack
        direction='row'
        alignItems='center'
        spacing='8px'
        marginBottom='10px'
      >
        <Icon iconType='account' />
        <Typography variant='medium' fontWeight='medium'>
          {accountCalculatedValues.accountId}
        </Typography>
        {!isChallengeAccountType && (
          <>
            <Styled.Badge>{account.product}</Styled.Badge>
            <Styled.Badge>
              {translate(`COMMON.LABELS.${account.accountType.toUpperCase()}`)}
            </Styled.Badge>
            <Styled.Badge>{account.currency}</Styled.Badge>
          </>
        )}
        {account.isInUse ? (
          <Styled.Badge $isActive>
            {translate('COMMON.LABELS.ACTIVE')}
          </Styled.Badge>
        ) : (
          <ChangeAccountButton account={account} challenge={challenge} />
        )}
      </Stack>
      <Styled.Info>
        <Styled.Portfolio>
          <Styled.PortfolioItem width='80px'>
            <Typography>{translate('COMMON.LABELS.P_L')}</Typography>
            <Typography>{accountCalculatedValues.pl}</Typography>
          </Styled.PortfolioItem>
          <Styled.PortfolioItem width='90px'>
            <Typography>{translate('COMMON.LABELS.EQUITY')}</Typography>
            <Typography>{accountCalculatedValues.equity}</Typography>
          </Styled.PortfolioItem>
          <Styled.PortfolioItem width='100px'>
            <Typography>{translate('COMMON.LABELS.FREE_MARGIN')}</Typography>
            <Typography>{accountCalculatedValues.freeMargin}</Typography>
          </Styled.PortfolioItem>
          <Styled.PortfolioItem
            width={
              currentLng === 'de' || currentLng === 'pl' ? '125px' : '100px'
            }
          >
            <Typography>{translate('COMMON.LABELS.USED_MARGIN')}</Typography>
            <Typography>{accountCalculatedValues.usedMargin}</Typography>
          </Styled.PortfolioItem>
          <Styled.PortfolioItem width='100px'>
            <Typography>{translate('COMMON.LABELS.MARGIN_LEVEL')}</Typography>
            <Typography>{accountCalculatedValues.marginLevel}</Typography>
          </Styled.PortfolioItem>
          {isLiveAccount && (
            <Styled.PortfolioItem width='165px'>
              <Typography>
                {translate('COMMON.LABELS.AVAILABLE_FOR_WITHDRAWAL')}
              </Typography>
              <Typography>
                {accountCalculatedValues.availableForWithdrawal}
              </Typography>
            </Styled.PortfolioItem>
          )}
        </Styled.Portfolio>
        <Stack direction='row' spacing='10px'>
          {isLiveAccount && <AddFundsButton account={account} />}
          {!isChallengeAccountType && (
            <Styled.OutlinedButton onClick={handleOpenAccountStatementModal}>
              {translate('COMMON.LABELS.ACCOUNT_STATEMENT')}
            </Styled.OutlinedButton>
          )}
        </Stack>
      </Styled.Info>
    </Styled.Account>
  );
});
