import React from 'react';
import { observer } from 'mobx-react-lite';
import { useMediaQuery, useTheme } from '@mui/material';

import { TInstrumentEntity, useMst } from '@trader/store';
import { defaultIcon, iconSizes } from '@trader/constants';
import { IconButton, Wrapper, Typography } from '@trader/components';

import * as Styled from './styled';

interface IModalTitle {
  symbol: string;
}

export const PlaceOrderHeader: React.FC<IModalTitle> = observer(
  ({ symbol }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const store = useMst();
    const instrument =
      store.entities.instruments.get<TInstrumentEntity>(symbol);

    return (
      <Styled.Root>
        <Wrapper alignItems='center' padding={isMobile ? '8px 0' : undefined}>
          <Styled.Image
            src={instrument.iconUrl || defaultIcon}
            alt='instrument icon'
            width={isMobile ? iconSizes.medium : iconSizes.small}
            height={isMobile ? iconSizes.medium : iconSizes.small}
          />
          <Typography
            variant={isMobile ? 'heading' : 'medium'}
            fontWeight='bold'
            color={theme.palette.common.black}
          >
            {symbol}
          </Typography>
        </Wrapper>
        {!isMobile && (
          <Styled.CloseIcon>
            <IconButton
              iconType='remove'
              onClick={() => store.ui.sidebar.closeRightBarOrderSection()}
            />
          </Styled.CloseIcon>
        )}
      </Styled.Root>
    );
  }
);
