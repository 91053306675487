import React from 'react';
import { observer } from 'mobx-react-lite';

import { Controller, Form, Stack } from '@trader/components';
import { useI18next } from '@trader/services';
import { getPipSizeNumber, getTime } from '@trader/utils';
import { useMst } from '@trader/store';
import { highMultiplier, lowMultiplier } from '@trader/constants';

import { StopLoss } from '../../../stopLoss';
import { useBackTestingFormValidation } from './formValidation';
import { SessionRange } from './sessionRange';
import { Quantity } from './quantity';

import * as Styled from './styled';

export interface IBackTestingInitialValues {
  quantity: number;
  lowestMultiplier: number;
  highestMultiplier: number;
  multiplierStep: number;
  oneTradePerSession: boolean;
  allowAveraging: boolean;
  isSessionRange: boolean;
  isSl: boolean;
  sl: number;
  from: Date;
  to: Date;
  sessionFrom: Date | undefined;
  sessionTo: Date | undefined;
}

export const BackTestConfig: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const validationSchema = useBackTestingFormValidation();

  const muliBands = store.pages.muliBands;
  const backTesting = muliBands.backTesting;

  const defaultFormValues = {
    lowestMultiplier: backTesting.lowestMultiplier,
    highestMultiplier: backTesting.highestMultiplier,
    multiplierStep: backTesting.multiplierStep,
    from: backTesting.from,
    to: backTesting.to,
    isSl: !!backTesting.sl,
    sl: backTesting.sl,
    isSessionRange: !!backTesting.sessionFrom,
    sessionFrom: backTesting.sessionFrom || undefined,
    sessionTo: backTesting.sessionTo || undefined,
    oneTradePerSession: backTesting.oneTradePerSession,
    allowAveraging: backTesting.allowAveraging,
  };

  const handleSubmit = (data: IBackTestingInitialValues) => {
    const {
      sessionTo,
      sessionFrom,
      isSessionRange,
      isSl,
      sl,
      ...backTestConfig
    } = data;

    backTesting.startBackTestingAsync.run({
      symbol: backTesting.getSymbol(),
      backTestConfig: {
        ...backTestConfig,
        sessionRange:
          isSessionRange && sessionFrom && sessionTo
            ? {
                from: getTime(sessionFrom),
                to: getTime(sessionTo),
              }
            : undefined,
        sl: isSl ? sl : undefined,
      },
    });
  };

  const cancelStrategyRunning = () => {
    backTesting.cancelBackTestRunningAsync.run();
  };

  return (
    <Styled.Root>
      <Form<IBackTestingInitialValues>
        onSubmit={(_reset, data) => {
          handleSubmit(data);
        }}
        mode='all'
        defaultValues={defaultFormValues}
        validationSchema={validationSchema}
      >
        {({ control }) => (
          <>
            <Stack direction='row' spacing='24px' width='100%'>
              <Styled.MainInputs>
                <Quantity />
                <Controller
                  type='tradingInput'
                  shouldCheckActivityOfButtons
                  minValue={lowMultiplier}
                  maxValue={highMultiplier}
                  step={lowMultiplier}
                  fixDigitAfterDot={getPipSizeNumber(lowMultiplier)}
                  control={control}
                  name='lowestMultiplier'
                  customLabel={translate('MULI_BANDS.LOWEST_MULTIPLIER')}
                />
                <Controller
                  type='tradingInput'
                  shouldCheckActivityOfButtons
                  minValue={lowMultiplier}
                  maxValue={highMultiplier}
                  step={lowMultiplier}
                  fixDigitAfterDot={getPipSizeNumber(lowMultiplier)}
                  control={control}
                  name='highestMultiplier'
                  customLabel={translate('MULI_BANDS.HIGHEST_MULTIPLIER')}
                />
                <Controller
                  type='tradingInput'
                  shouldCheckActivityOfButtons
                  minValue={lowMultiplier}
                  maxValue={highMultiplier}
                  step={lowMultiplier}
                  fixDigitAfterDot={getPipSizeNumber(lowMultiplier)}
                  control={control}
                  name='multiplierStep'
                  customLabel={translate('MULI_BANDS.MULTIPLIER_STEP')}
                />
                <Controller
                  type='datePickerField'
                  label={translate('MULI_BANDS.FROM_DATE')}
                  name='from'
                  shouldHideControlInfo
                />
                <Controller
                  type='datePickerField'
                  label={translate('MULI_BANDS.TO_DATE')}
                  name='to'
                  shouldHideControlInfo
                />
              </Styled.MainInputs>
              <Stack direction='column' spacing='4px'>
                <SessionRange />
                <StopLoss />
                <Controller
                  type='switcherField'
                  label={translate('MULI_BANDS.ALLOW_AVERAGE')}
                  name='allowAveraging'
                />
                <Controller
                  type='switcherField'
                  label={translate('MULI_BANDS.ONE_TRADE_PER_SESSION')}
                  name='oneTradePerSession'
                />
              </Stack>
            </Stack>
            {backTesting.isPending ? (
              <Styled.ApplyChanges
                loading={backTesting.isPending}
                fullWidth
                type='button'
                onClick={cancelStrategyRunning}
              >
                {translate('COMMON.LABELS.CANCEL')}
              </Styled.ApplyChanges>
            ) : (
              <Styled.ApplyChanges
                loading={backTesting.isPending}
                disabled={backTesting.isPending}
                fullWidth
                type='submit'
              >
                {translate('COMMON.LABELS.EXECUTE')}
              </Styled.ApplyChanges>
            )}
          </>
        )}
      </Form>
    </Styled.Root>
  );
});
