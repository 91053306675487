import styled, { css } from 'styled-components';

import { conditionApply } from '@trader/utils';

export const Image = styled('img')`
  ${({ theme }) => css`
    ${conditionApply(
      [theme.direction === 'rtl'],
      `
        margin-left: 10px;
      `
    )}
  `};
`;
