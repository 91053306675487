import React from 'react';
import { observer } from 'mobx-react-lite';
import { useMediaQuery, useTheme } from '@mui/material';

import { TInstrumentEntity, useMst } from '@trader/store';
import { useI18next } from '@trader/services';
import { Stack } from '@trader/components';
import { EConditionType, useBusinessLogic, useNavigation } from '@trader/hooks';
import { TPlaceOrderSide } from '@trader/types';
import { formatByPipSize } from '@trader/utils';
import { NAVIGATE_TO } from '@trader/constants';

import * as Styled from './styled';

export interface IActionButtons {
  symbol: string;
  layout?: string;
  ask: number;
  bid: number;
  pipSize: number;
  direction?: 'row' | 'column';
  buttonVariant?: 'text' | 'outlined' | 'contained';
}

export const ActionButtons: React.FC<IActionButtons> = observer(
  ({ direction = 'row', buttonVariant = 'contained', ...rest }) => {
    const { translate } = useI18next();
    const { navigateTo } = useNavigation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const store = useMst();

    const { applyCondition } = useBusinessLogic(
      EConditionType.RegistrationCompleted
    );
    const { applyCondition: applyTradingAllowedCondition } = useBusinessLogic(
      EConditionType.TradingAllowed
    );

    const isDailyTargetGoalReached = store.user.profile.blockedDueToDailyTarget;

    const trading = store.trading.getTrading('createOrder');
    const isInvestmentAccProduct = store.user.isInvestmentAccProduct();
    const instrument = store.entities.instruments.get<TInstrumentEntity>(
      rest.symbol
    );

    const handleDesktopClick = (side: TPlaceOrderSide) => () => {
      store.ui.sidebar.openRightBarOrderSection();
      if (
        applyCondition().status !== 'failed' &&
        !instrument.disabledByMarketNews &&
        applyTradingAllowedCondition().status !== 'failed' &&
        instrument.tradingStatus !== 'CLOSE_ONLY' &&
        !isDailyTargetGoalReached
      ) {
        trading.getInstrumentReferenceAsync.run({
          symbol: rest.symbol,
          side,
        });
      }
    };

    const handleMobileClick = (side: TPlaceOrderSide) => () => {
      navigateTo(NAVIGATE_TO.main.trade);
      // Delay side update to ensure proper initialization.
      setTimeout(() => {
        trading.updateSide(side);
      }, 0);
    };

    return (
      <Stack className='action_buttons' direction={direction} spacing='5px'>
        <Styled.Button
          $isAsk
          disabled={
            isInvestmentAccProduct || !instrument.allowedOperations().sell.open
          }
          variant={buttonVariant}
          hasChildrenWrapper={false}
          onClick={
            isMobile ? handleMobileClick('Sell') : handleDesktopClick('Sell')
          }
        >
          <Styled.Content>
            <Styled.Label component='div'>
              {translate('COMMON.LABELS.SELL')}
            </Styled.Label>
            <Styled.Price component='div'>
              {formatByPipSize(instrument?.ask || rest.ask, rest.pipSize)}
            </Styled.Price>
          </Styled.Content>
        </Styled.Button>
        <Styled.Button
          variant={buttonVariant}
          disabled={!instrument.allowedOperations().buy.open}
          hasChildrenWrapper={false}
          onClick={
            isMobile ? handleMobileClick('Buy') : handleDesktopClick('Buy')
          }
        >
          <Styled.Content>
            <Styled.Label component='div'>
              {translate('COMMON.LABELS.BUY')}
            </Styled.Label>
            <Styled.Price component='div'>
              {formatByPipSize(instrument?.bid || rest.bid, rest.pipSize)}
            </Styled.Price>
          </Styled.Content>
        </Styled.Button>
      </Stack>
    );
  }
);
